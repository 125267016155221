import React from 'react'
import { graphql } from 'gatsby'
import { Breadcrumb } from 'gatsby-plugin-breadcrumb'
import { Section, SectionFullWidth } from '../styles/common/Common'
import { BreadcrumbWrapper, Item, ItemWrapper } from '../styles/common/Element'
import ContactBanner from '../components/Banner/ContactBanner'
import Hero from '../components/Hero'
import BlockContent from '../components/BlockContent'
import ImageZoom from '../components/ImageZoom'
import SEO from '../components/SEO'
import Image from '../components/Image'
import styled from 'styled-components'
import mq from '../styles/media-queries'
import { Container } from '../styles/common/Layout'


const ItemNoPadding = styled(Item)`
  padding: 0px 10px 30px;
  margin-top: 20px;

  ${mq.md} {
    padding: 0px 30px 30px;
    margin-top: 0px;
  }
`
export default function PraxisPage({ pageContext, data }) {
  const {
    breadcrumb: { crumbs },
  } = pageContext
  return (
    <>
      <SEO
        title={"Unsere Praxis"}
      />
      <Hero slug={data.page.slug.current} small>
        <h1 className="headline">{data.page.pageName}</h1>
        <h2>{data.page.subHeadline}</h2>
      </Hero>
      <Container as="section">
        <BreadcrumbWrapper>
          <Breadcrumb crumbs={crumbs} crumbLabel={data.page.pageName} />
        </BreadcrumbWrapper>
        <ItemWrapper fullWidth>
          <Item isImage>
            <Image image={data.page.contentImage.asset.gatsbyImageData} alt={data.page.pageName} />
          </Item>
          <ItemNoPadding>
            <BlockContent blocks={data.page._rawText} />
          </ItemNoPadding>
        </ItemWrapper>
      </Container>
      {data.page.slider &&
        <SectionFullWidth>
          <ItemWrapper fullWidth>
            {data.page.slider.map(image => (
              <Item key={image._key} isImage>
                <ImageZoom image={image.asset.gatsbyImageData} alt={data.page.pageName} />
              </Item>
            ))}
          </ItemWrapper>
        </SectionFullWidth>
      }
      <ContactBanner />
    </>
  )
}


export const query = graphql`
  query PraxisPageQuery {
    page: sanityPageContent(slug: {current: {eq: "praxis"}}) {
      id
      pageName
      slug {
        current
      }
      subHeadline
      _rawText(resolveReferences: {maxDepth: 10})
      slider {
        _key
        asset {
          gatsbyImageData(fit: FILLMAX, placeholder: BLURRED)
        }
      }
      contentImage {
        asset {
          gatsbyImageData(fit: FILLMAX, placeholder: BLURRED)
        }
      }
    }
  }
`;
